import http from '@/common/utils/http'

/** 获取电梯详情 */
export function getElevatorInfo(data) {
  return http({
    url: `maixin/wuye/getElevatorInfo`,
    method: 'post',
    data
  })
}

/** 获取故障与困人记录 */
export function getElevatorHlsInfo(data) {
  return http({
    url: `RuiJin/getElevatorHlsInfo`,
    method: 'post',
    data
  })
}

export function getElevatorHeatMapStatistics(data) {
    return http({
        url: `elevator/elevatorHeatMapNew`,
        method: 'post',
        data
    })
}

// 获取二维码
export function getElevatorQRCodeImg(data) {
  return http({
      url: `elevator/getElevatorQRCodeImg?elevatorId=${data}`,
      method: 'get',
  })
}
